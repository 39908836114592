import React, { useEffect, useState, forwardRef, useRef } from "react"

/* FullScreen */
import { FullScreen, useFullScreenHandle } from "react-full-screen"

/* Picture */
import { Picture, GameModal, ModalAnimated, Cash } from "../components"

/* Widgets */
import History from "./History"
import MobilePayout from "./MobilePayout"
import Rules from "./Rules"

/* Constants */
import { env, sound, utils } from "../constants"


const Background = forwardRef(({ children, payout, rules, historyData, loadMore, gameInfo, volume, setVolume }, ref) => {

    /* Detect Safari */
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

    /* Hooks */
    const [historyVisible, setHistoryVisible] = useState(false)
    const [rulesVisivle, setRulesVisivle] = useState(false)

    const [menuVisible, setMenuVisible] = useState(false)
    const [selected, setSelected] = useState("")

    const [isPlayed, setIsPlayed] = useState(false)
    const [muted, setMuted] = useState(true)

    /* Refs */
    const screen = useFullScreenHandle()
    const modalRef = useRef(null)
    const musicRef = useRef(null)
    const volumeRef = useRef(null)



    let hidden = null
    let visibilityChange = null
    if (typeof document.hidden !== 'undefined') {
        hidden = 'hidden'
        visibilityChange = 'visibilitychange'
    }
    else if (typeof document.msHidden !== 'undefined') {
        hidden = 'msHidden'
        visibilityChange = 'msvisibilitychange'
    }
    else if (typeof document.webkitHidden !== 'undefined') {
        hidden = 'webkitHidden'
        visibilityChange = 'webkitvisibilitychange'
    }


    const click = event => {
        if (!isPlayed) {
            if ((musicRef.current && !musicRef.current.contains(event.target)) && (volumeRef.current && !volumeRef.current.contains(event.target))) {

                sound.backgroundSound.play()

                sound.soundEffects.forEach(soundEffect => {
                    soundEffect.audio.play()
                    soundEffect.audio.pause()
                })

                setIsPlayed(true)
                setMuted(false)
                setVolume(true)
            }
        }
    }


    /* On Mount */
    useEffect(() => {

        const handleVisibilityChange = () => {
            if (isPlayed) {
                if (document[hidden]) {
                    sound.backgroundSound.stop()
                }
                else {
                    if (!muted) {
                        sound.backgroundSound.play()
                    }
                }
            }
        }

        const handleClickOutside = event => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setHistoryVisible(false)
                setRulesVisivle(false)
            }
        }

        if (!isPlayed) {
            document.addEventListener('click', click, true)
        }

        document.addEventListener('click', handleClickOutside, true)
        document.addEventListener(visibilityChange, handleVisibilityChange, false)

        return () => {
            sound.backgroundSound.stop()
            document.removeEventListener('click', click, true)
            document.removeEventListener('click', handleClickOutside, true)
            document.removeEventListener(visibilityChange, handleVisibilityChange)
        }
    }, [isPlayed, visibilityChange, hidden, muted])


    const mute = () => {
        if (isPlayed) {

            if (!muted) {
                sound.backgroundSound.pause()
            }
            else {
                sound.backgroundSound.play()
            }

            setMuted(!muted)
        }
    }

    /* Full Screen handler */
    const handle = () => {

        try {
            if (screen.active === true) {
                screen.exit()
            }
            else {
                screen.enter()
            }
        }
        catch {
            console.log("Fullscreen error")
        }

    }

    const toggleButton = (type) => {
        if (type === 'history') {
            setHistoryVisible(!historyVisible)
            setRulesVisivle(false)
        }
        if (type === 'rules') {
            setRulesVisivle(!rulesVisivle)
            setHistoryVisible(false)
        }
    }


    return (
        <FullScreen handle={screen} className="fullscreen">
            <div id="sweetforsweet">

                {/* Game control buttons */}
                <div className="game-controls">

                    <div className={`game-control mobile-hide ${historyVisible ? 'active' : 'default'}`} onClick={() => toggleButton('history')}>
                        <Picture src={`${env.mediapoint}/images/history.png`} alt="History" />
                    </div>

                    <div className={`game-control mobile-hide ${rulesVisivle ? 'active' : 'default'}`} onClick={() => toggleButton('rules')}>
                        <Picture src={`${env.mediapoint}/images/info.png`} alt="Info" />
                    </div>

                    <div ref={volumeRef} className="game-control" onClick={() => isPlayed && setVolume(!volume)}>
                        {volume ? <Picture src={`${env.mediapoint}/images/volume.png`} alt="Volume" /> : <Picture src={`${env.mediapoint}/images/volume-mute.png`} alt="Volume" />}
                    </div>

                    <div ref={musicRef} className="game-control" onClick={() => mute()}>
                        {muted ? <Picture src={`${env.mediapoint}/images/music-off.png`} alt="Volume" /> : <Picture src={`${env.mediapoint}/images/music-on.png`} alt="Volume" />}
                    </div>

                    {!isSafari &&
                        <div className="game-control" onClick={() => handle()}>
                            <Picture src={`${env.mediapoint}/images/fullscreen.png`} alt="Fullscreen" />
                        </div>
                    }
                </div>

                {gameInfo && gameInfo.number && <div className="game-control-number">#{gameInfo.number}</div>}

                <div ref={modalRef}>

                    {/* Rules */}
                    <ModalAnimated visible={rulesVisivle}>
                        <Rules list={rules} close={() => setRulesVisivle(false)} />
                    </ModalAnimated>

                    <ModalAnimated visible={historyVisible}>
                        <History historyData={historyData} loadMore={() => loadMore()} close={() => setHistoryVisible(false)} />
                    </ModalAnimated>

                </div>


                {children}

                <div className="menu-toolbar" onClick={() => setMenuVisible(true)}>
                    <Picture src={`${env.mediapoint}/images/menu/burger.png`} alt="burger" />
                </div>

                <GameModal
                    visible={menuVisible}
                    close={() => setMenuVisible(false)}
                    selected={selected}
                    setSelected={selected => setSelected(selected)}
                >

                    <div className="menu-content">
                        {selected === '' &&
                            <div className="menu-select">

                                <div className="menu-select-button" onClick={() => setSelected('history')}>
                                    <Picture src={`${env.mediapoint}/images/menu/history.png`} alt='history' />
                                    <p>{utils.translate('History')}</p>
                                </div>
                                <div className="menu-select-button" onClick={() => setSelected('rules')}>
                                    <Picture src={`${env.mediapoint}/images/menu/rules.png`} alt='rules' />
                                    <p>{utils.translate('Rules')}</p>
                                </div>
                                <div className="menu-select-button" onClick={() => setSelected('payout')}>
                                    <Picture src={`${env.mediapoint}/images/menu/payout.png`} alt='payout' />
                                    <p>{utils.translate('Payout')}</p>
                                </div>

                                <Cash mobile />

                                <div className="game-controls menu">

                                    <div className="game-control" onClick={() => mute()}>
                                        {muted ? <Picture src={`${env.mediapoint}/images/music-off.png`} alt="Volume" /> : <Picture src={`${env.mediapoint}/images/music-on.png`} alt="Volume" />}
                                    </div>

                                    <div className="game-control" onClick={() => setVolume(!volume)}>
                                        {volume ? <Picture src={`${env.mediapoint}/images/volume.png`} alt="Volume" /> : <Picture src={`${env.mediapoint}/images/volume-mute.png`} alt="Volume" />}
                                    </div>

                                    {!isSafari &&
                                        <div className="game-control" onClick={() => handle()}>
                                            <Picture src={`${env.mediapoint}/images/fullscreen.png`} alt="Fullscreen" />
                                        </div>
                                    }
                                </div>
                            </div>
                        }

                        {selected === 'history' && <History historyData={historyData} mobile={true} loadMore={() => loadMore()} />}
                        {selected === 'payout' && <MobilePayout payout={payout} />}
                        {selected === 'rules' && <Rules mobile={true} list={rules} close={() => setRulesVisivle(false)} />}

                    </div>
                </GameModal>

            </div>
        </FullScreen>
    )

})

export default Background