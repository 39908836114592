import React, { PureComponent } from "react"

/* Components */
import { Chip } from '../components'

/* Constants */
import { env, utils } from '../constants'

/* Picture */
import { Picture } from "../components"


/* Widget Insurance Chips */
class InsuranceChips extends PureComponent {

    /* Calculate */
    calculate = () => {

        const { ante, maxPay, playerGame } = this.props

        if (ante && playerGame) {

            let multiplier = 0

            if (playerGame) {
                multiplier = parseInt(playerGame.multiplier)
            }

            const total = parseFloat(ante) * multiplier * 2 > parseFloat(maxPay) ? parseFloat(maxPay) : parseFloat(ante) * multiplier * 2

            return [total * 0.25, total * 0.5, total * 0.75, total]
        }

        return []
    }


    /* Get Color */
    getColor = value => {

        const { chips } = this.props

        let color = chips[0].color

        chips.forEach(item => {
            if (item.value <= value) {
                color = item.color
            }
        })

        return color
    }

    render = () => {

        const { balance, ante, insuranceModal, close, setInsurance } = this.props
        const values = this.calculate()

        return (
            <div className={`insurance-container ${insuranceModal ? 'visible' : 'closed'}`}>

                <div className="insurance-container-text">{utils.translate("Select insurance chip")}</div>

                <div className="insurance-container-box">
                    {values.map((chip, index) => {
                        const disabled = parseInt(chip) + ante * 2 > balance
                        const style = disabled ? "chip disabled" : "chip"
                        return (
                            <div className="insurance-item" key={`${index}`}>
                                <div className={`insurance-item-text ${disabled ? 'hidden' : 'visible'}`}>{(index + 1) * 25}%</div>
                                <div onClick={() => !disabled && setInsurance(chip)} className={style}>
                                    <Chip color={this.getColor(chip)} value={chip} />
                                </div>
                            </div>
                        )
                    })}
                </div>


                <div onClick={() => close()} className="insurance-close">
                    <Picture src={`${env.mediapoint}/images/close.png`} alt="Close" />
                    <span>{utils.translate("Close")}</span>
                </div>

            </div>
        )
    }

}

export default InsuranceChips