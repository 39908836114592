import { env } from "."

/* Components */
import { Picture, Chip, Bet, Fold, Fifth, Exchange, Insurance } from '../components'

/* Fields */
const chips = [
    { value: 1, color: "#f44336" },
    { value: 5, color: "#8bc34a" },
    { value: 25, color: "#2196f3" },
    { value: 100, color: "#ff9800" },
    { value: 500, color: "#673ab7" },
    { value: 1000, color: "#222222" },
]

/* RU */
const ru = [
    {
        id: 1,
        title: 'Правила игры',
        content:
            <div className="rule-content">

                <h3>ЕВРОПЕЙСКИЙ ПОКЕР</h3>

                <p>Цель игры – в наборе покерной комбинации карт более сильной, чем у дилера.</p>
                <p>Для игры используется одна стандартная колода из 52-х карт.</p>
                <p>Игрок делает ставку Анте (Ante) в пределах минимума и максимума стола и получает только 4 карты. У дилера 5 карт, одна из них открыта.</p>
                <p>Если игрок, посмотрев свои 4 карты, отказывается от игры, то он проигрывает только половину ставки Анте. Либо игрок может играть на 4-х картах. Если игрок принял решение играть, то он должен поставить ставку Бет, равную двум Анте.</p>
                <p>За сумму, равную ставке Анте, игрок может докупить пятую карту или обменять от одной до четырех карт, причем при обмене игрок получает на одну карту больше. Таким образом после обмена у игрока будет 5 карт. После этого игрок принимает решение – продолжать игру или нет.</p>
                <p>Если игрок принял решение играть, то он должен поставить ставку Бет, равную двум Анте. Если игрок принимает решение не играть (Пас) – теряет при этом ставку Анте. После того, как игроки приняли решение, дилер открывает свои карты.</p>
                <p>Если у дилера есть игра, он сравнивает ее с покерными комбинациями игроков. У дилера есть игра, если в его картах есть комбинация Туз – Король или выше. Если у дилера нет игры – оплачивается только ставка Анте 1:1. Если у игрока комбинация старше, чем у дилера, оплачивается ставка Анте 1:1 и ставка Бет в соответствии с таблицей выплат.</p>
                <p> Если игровая комбинация игрока ниже, чем у дилера, то его ставки Анте и Бет проигрывают.</p>
                <p>Если игрок имеет комбинацию Стрит и выше, то он может застраховать свою комбинацию от ситуации «нет игры» у дилера. Для этого он должен поставить ставку в размере 25, 50, 75 или 100 процентов от ожидаемой выплаты по Бет. Если выплата по Бет превышает максимальную выплату стола, то игрок ставит ставку в размере 25, 50, 75 или 100 процентов от максимальной выплаты стола. Ставка Страхование выигрывает в случае, если у дилера нет игры. В противном случае ставка Страхование проигрывает. Ставка Страхование оплачивается 1:1.</p>
                <p>Если игрок имеет комбинацию Стрит и выше, а у дилера нет игры, игрок может за сумму, равную ставке Анте, попытаться купить игру дилеру. При этом дилер меняет старшую карту. Если после обмена карты игровой комбинации у дилера не возникает, оплата ставки Анте не производится. Если дилер купил игру, то при выигрыше игрока оплачивается только ставка Бет в соответствии с таблицей выплат.</p>
                <p>Максимальная выплата – сумма, которая может быть выплачена на комбинацию, включая страховку. В Максимальную выплату не входят непосредственно сами ставки на Анте, Бет и Страхование.</p>

                <p>Таблица выплат</p>
                <table className="rules-table">
                    <tbody>
                        <tr>
                            <td>Туз-Король на 5-ти картах</td>
                            <td>1:1</td>
                        </tr>
                        <tr>
                            <td>Туз-Король на 4-х картах</td>
                            <td>3:1</td>
                        </tr>
                        <tr>
                            <td>Пара</td>
                            <td>1:1</td>
                        </tr>
                        <tr>
                            <td>Две пары</td>
                            <td>3:1</td>
                        </tr>
                        <tr>
                            <td>Тройка</td>
                            <td>4:1</td>
                        </tr>
                        <tr>
                            <td>Стрит</td>
                            <td>5:1</td>
                        </tr>
                        <tr>
                            <td>Флеш</td>
                            <td>6:1</td>
                        </tr>
                        <tr>
                            <td>Фулл хаус</td>
                            <td>7:1</td>
                        </tr>
                        <tr>
                            <td>Каре на 5-ти картах</td>
                            <td>20:1</td>
                        </tr>
                        <tr>
                            <td>Каре на 4-ти картах</td>
                            <td>40:1</td>
                        </tr>
                        <tr>
                            <td>Стрит флеш</td>
                            <td>50:1</td>
                        </tr>
                        <tr>
                            <td>Роял флеш</td>
                            <td>100:1</td>
                        </tr>
                    </tbody>
                </table>

                <p>В случае совпадения комбинаций победитель определяется по старшей карте в покерной комбинации, а далее по следующим старшим картам. В комбинации «Стрит» 5-4-3-2-А туз являтся младшей картой.</p>

                <p>При полном совпадении всех карт в комбинации – «Stand off» (ничья).</p>

            </div>
    },
    {
        id: 2,
        title: 'Максимальная выплата',
        content:
            <div className="rule-content">
                <p>Максимальная выплата – сумма, которая может быть выплачена на бокс, включая Страхование. В Максимальную выплату не входят непосредственно сами ставки на Анте, Бет и Страхование.</p>
            </div>
    },
    {
        id: 3,
        title: 'Покерные комбинации',
        content:
            <div className="rule-content">

                <p>- Отдельные карты перечислены по убыванию: туз (старший или младший), король, дама, валет, 10, 9, 8, 7, 6, 5, 4, 3 и 2.
                    Туз может быть самой старшей картой в Стрите из туза, короля, дамы, валета и 10 или самой младшей в Стрите из 5, 4, 3, 2 и туза.
                    Возможные комбинации в порядке убывания:</p>

                <p>Флеш-рояль - это Стрит-флеш из туза, короля, дамы, валета и 10 одной масти.</p>
                <div className="rules-combinations">
                    <div className="rules-combination">
                        <div className="rules-combination-text">10</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">J</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">Q</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">K</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">A</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                </div>

                <p>Стрит-флеш - это комбинация, в состав которой входит пять последовательных карт одной масти; например: десятка ,девятка, восьмерка, семерка и шестерка, все червовой масти. Два Стрит-флеша сравниваются по самой старшей карте в каждом из них.</p>
                <div className="rules-combinations">
                    <div className="rules-combination">
                        <div className="rules-combination-text">6</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">7</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">8</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">9</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">10</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                </div>


                <p>
                    Каре - это комбинация, в состав которой входит четыре карты одного достоинство и любая другая
                    карта. Например, четыре четверки в руке. Каре из карт более высокого
                    достоинства старше КАРЕ из карт более низкого достоинства.
                </p>
                <div className="rules-combinations">
                    <div className="rules-combination">
                        <div className="rules-combination-text">4</div>
                        <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">4</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">4</div>
                        <Picture src={`${env.mediapoint}/images/suits/diamond.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">4</div>
                        <Picture src={`${env.mediapoint}/images/suits/clubs.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">A</div>
                        <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Heart" />
                    </div>
                </div>


                <p>
                    Фулл-хаус - это комбинация, в состав которой входят три карты
                    одинакового достоинства и пара из карт другого достоинства, например: три
                    короля и две двойки. Из двух рук с фулл-хаусом старшей является та, в состав
                    которой входят три карты более высокого достоинства.
                </p>

                <div className="rules-combinations">
                    <div className="rules-combination">
                        <div className="rules-combination-text">K</div>
                        <Picture src={`${env.mediapoint}/images/suits/diamond.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">K</div>
                        <Picture src={`${env.mediapoint}/images/suits/clubs.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">K</div>
                        <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">2</div>
                        <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">2</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                </div>

                <p>
                    Флеш - это комбинация, в состав которой входят любые пять карт одной масти в любой
                    последовательности, например: пять карт червовой масти. Два флеша сравниваются
                    как руки со старшей картой; для определения победителя сравнивается самая старшая
                    карта в каждой из рук. Если в обеих руках совпадают карты самого высокого достоинства,
                    сравнивается вторая самая высокая по достоинству карта. И так до тех пор, пока не будет
                    определен победитель. Если все карты одного достоинства - НИЧЬЯ.
                </p>
                <div className="rules-combinations">
                    <div className="rules-combination">
                        <div className="rules-combination-text">3</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">6</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">9</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">10</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">Q</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                </div>

                <p>
                    Стрит - это комбинация, в состав которой входят пять последовательных карт
                    разных мастей, например: Туз, двойка, тройка, чертверка и пятерка двух
                    или более мастей. Два Стрита сравниваются по самой старшей карте в каждой из рук.
                    Два Стрита с одинаковыми старшими картами имеют одинаковую ценность,
                    поскольку масти в сравнении не участвуют.
                </p>
                <div className="rules-combinations">
                    <div className="rules-combination">
                        <div className="rules-combination-text">A</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">2</div>
                        <Picture src={`${env.mediapoint}/images/suits/diamond.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">3</div>
                        <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">4</div>
                        <Picture src={`${env.mediapoint}/images/suits/clubs.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">5</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                </div>

                <p>
                    Тройка - это комбинация, в состав которой входят три карты одного достоинства и две неодинаковые
                    карты другого достоинства. Например, три ДАМЫ.
                </p>
                <div className="rules-combinations">
                    <div className="rules-combination">
                        <div className="rules-combination-text">Q</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">Q</div>
                        <Picture src={`${env.mediapoint}/images/suits/diamond.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">Q</div>
                        <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">2</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">10</div>
                        <Picture src={`${env.mediapoint}/images/suits/clubs.png`} alt="Heart" />
                    </div>
                </div>

                <p>Две пары - это комбинация, в состав которой входят две карты одного достоинства, две карты
                    другого достоинства (которые соответствуют друг другу, но не первой паре) и любая другая карта.
                </p>
                <p>
                    Например: ДВА ТУЗА и ДВА КОРОЛЯ. Для оценки
                    двух рук с двумя парами сначала сравнивается самая высокая пара каждой из рук.
                    Побеждает рука с самой высокой парой. Если в обеих руках имеется одинаковая по
                    величине самая высокая пара, сравнивается вторая пара каждой из рук. Если в обеих
                    руках имеются две равные пары, победитель определяется по следующей карте.
                </p>
                <div className="rules-combinations">
                    <div className="rules-combination">
                        <div className="rules-combination-text">A</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">A</div>
                        <Picture src={`${env.mediapoint}/images/suits/diamond.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">K</div>
                        <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">K</div>
                        <Picture src={`${env.mediapoint}/images/suits/clubs.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">3</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                </div>

                <p>
                    Пара - это комбинация, в состав которой входят две карты одного достоинствв (например: ДВА
                    КОРОЛЯ) и три неодинаковые карты другого достоинства.
                </p>

                <p>
                    Пара - эта самая слабая комбинация, которая может победить в игре. Пары из карт более
                    высокого достоинствв побеждают пары из карт более низкого достоинствв. Если в двух руках
                    имеется одинаковая пара, сравниваются следующие карты в порядке убывания.
                </p>

                <div className="rules-combinations">
                    <div className="rules-combination">
                        <div className="rules-combination-text">K</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">K</div>
                        <Picture src={`${env.mediapoint}/images/suits/diamond.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">3</div>
                        <Picture src={`${env.mediapoint}/images/suits/diamond.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">7</div>
                        <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">Q</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                </div>

                <p>
                    Туз Король – минимальная игра. Это рука, в которую входят Туз и Король и три
                    неодинаковые карты другого ранга.
                </p>
                <p>
                    Если в двух руках имеется Туз Король, сравниваются следующие карты в порядке
                    убывания.
                </p>

                <div className="rules-combinations">
                    <div className="rules-combination">
                        <div className="rules-combination-text">A</div>
                        <Picture src={`${env.mediapoint}/images/suits/clubs.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">K</div>
                        <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">3</div>
                        <Picture src={`${env.mediapoint}/images/suits/diamond.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">7</div>
                        <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">Q</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                </div>

            </div>
    },
    /*{
        id: 5,
        title: 'Бонус от дилера',
        content:
            <div className="rule-content">
                <p>Бонус – дополнительная ставка, которую может поставить на дилера.</p>
                <p>Ставка Бонус выигрывает, если с пяти карт есть комбинация три одинаковых карты «Тройка» или выше и оплачивается в соответствии с таблицей выплат по открытию дилерских карт. В остальных случаях ставка на Бонус проигрывает.</p>
                <p>Таблица выплат</p>
                <table className="rules-table">
                    <tbody>
                        <tr>
                            <td>Тройка</td>
                            <td>10:1</td>
                        </tr>
                        <tr>
                            <td>Стрит</td>
                            <td>40:1</td>
                        </tr>
                        <tr>
                            <td>Флеш</td>
                            <td>50:1</td>
                        </tr>
                        <tr>
                            <td>Фулл хаус</td>
                            <td>70:1</td>
                        </tr>
                        <tr>
                            <td>Каре</td>
                            <td>200:1</td>
                        </tr>
                        <tr>
                            <td>Стрит флеш</td>
                            <td>1500:1</td>
                        </tr>
                        <tr>
                            <td>Роял флеш</td>
                            <td>5000:1</td>
                        </tr>
                    </tbody>
                </table>
            </div>
    },*/
    {
        id: 6,
        title: 'Делайте ставки',
        content:
            <div className="rule-content">

                <p>Панель «ЛИМИТЫ СТАВОК» отображает минимальные и максимальные разрешенные лимиты ставок за столом, которые могут время от времени меняться. Откройте «Лимиты ставок», чтобы проверить свои текущие лимиты.</p>

                <div className="rule-component">
                    <div className="game-info-inner">
                        <div className="game-info-text">EUROPEAN POKER</div>
                        <div className="game-info-rate">$ 1 - $ 1000</div>
                    </div>
                </div>

                <p>Для участия в игре вам потребуется достаточное количество средств, необходимое для покрытия ставок. Ваш текущий БАЛАНС отображается на экране.</p>
                <div className="rule-component">
                    <div className="balance-box">
                        <div className="balance-text">БАЛАНС:</div>
                        <div className="balance-value">$ 1 270</div>
                    </div>
                </div>

                <p>Индикатор «ОТОБРАЖЕНИЕ ФИШЕК» позволяет выбирать достоинство каждой фишки, которую захотите разместить в виде ставки. Для выбора доступны только те фишки, номинал которых может быть покрыт вашим текущим балансом.</p>
                <div className="rule-component">
                    <div className="chips-container" style={{ transform: 'scale(0.4)' }}>

                        <div className="chips-button">
                            <Picture src={`${env.mediapoint}/images/delete.png`} alt="Delete" />
                            Очистить
                        </div>

                        <div className="chips-button">
                            <Picture src={`${env.mediapoint}/images/cancel.png`} alt="Cancel" />
                            Отменить
                        </div>

                        <div className="chips-box">
                            {chips.map((chip, index) =>
                                <div key={`${index}`} className="chip">
                                    <Chip color={chip.color} value={chip.value} />
                                </div>
                            )}
                        </div>

                        <div className="chips-button">
                            <span>2x</span>
                            Удвоить
                        </div>

                        <div className="chips-button">
                            <Picture src={`${env.mediapoint}/images/refresh.png`} alt="Refresh" />
                            Повторить
                        </div>

                    </div>
                </div>


                <p>После выбора фишки сделайте ставку, нажав на соответствующее место для ставки на игровом столе. При каждом нажатии на место для ставок сумма вашей ставки увеличивается на стоимость выбранной фишки или до максимально допустимого лимита для выбранного вами типа ставки. После размещения максимально допустимого лимита ставки дополнительные средства не принимаются, а над вашей ставкой появится сообщение с уведомлением о размещении максимальной ставки.</p>
                <div className="rule-component">
                    <div className="rates" style={{ position: "relative", top: 0, bottom: 0 }}>
                        <div className="bonus">
                            <div style={{ zIndex: 1 }} className="bonus-text">DEALER BONUS</div>
                        </div>
                        <div className="ante">
                            <div className="ante-text" style={{ zIndex: 1 }}>ANTE</div>
                        </div>
                        <div className="bet" style={{ opacity: 1 }}>
                            <div className="ante-text" style={{ zIndex: 1 }}>BET</div>
                        </div>
                    </div>
                </div>

                <p>ПРИМЕЧАНИЕ: Пожалуйста, не сворачивайте окно браузера и не открывайте другие вкладки в своем браузере, пока активно время размещения ставок и ставки размещаются на столе. Такие действия могут быть расцедены как выход из игры, и, следовательно, ваши ставки будут отклонены в этом раунде игры.</p>

                <p>Кнопка «ПОВТОРИТЬ» позволяет повторить все ставки из предыдущего раунда игры. Эта кнопка остается активной до тех пор, пока не будет размещена первая фишка.</p>

                <div className="rule-component">
                    <div className="chips-button">
                        <Picture src={`${env.mediapoint}/images/refresh.png`} alt="Refresh" />
                        Повторить
                    </div>
                </div>

                <p>Кнопка «УДВОИТЬ» (2×) становится доступной после размещения ставки. С каждым щелчком/нажатием все ваши ставки удваиваются вплоть до максимального предела. Обратите внимание, что вам необходимо иметь достаточное количество средств на счете для удвоения ВСЕХ ваших размещенных ставок.</p>

                <div className="rule-component">
                    <div className="chips-button">
                        <span>2x</span>
                        Удвоить
                    </div>
                </div>

                <p>Кнопка «ОТМЕНА» позволяет удалить последнюю размещенную ставку.</p>
                <p>Повторное нажатие кнопки «ОТМЕНА» позволяет отменить ставки по одной в обратном порядке их размещения. Чтобы удалить все свои ставки, удерживайте нажатой кнопку «ОТМЕНА».</p>
                <div className="rule-component">
                    <div className="chips-button">
                        <Picture src={`${env.mediapoint}/images/cancel.png`} alt="Cancel" />
                        Отменить
                    </div>
                </div>

                <p>Индикатор «ОБЩАЯ СТАВКА» отображает общую сумму ставок, размещенных в текущем раунде.</p>
                <div className="rule-component">
                    <div className="balance-box">
                        <div className="balance-text blue">ОБЩЯЯ СТАВКА:</div>
                        <div className="balance-value">$ 270</div>
                    </div>
                </div>

                <p>Примите решение</p>
                <p>После раздачи ваших карт появится ваша комбинация карт и окно</p>
                <div className="rule-component">
                    <Picture src="/rules/game.png" alt="Game" />
                </div>

                <p>Для покупки 5-ую карту нажать окно</p>
                <div className="rule-component">
                    <Fifth />
                </div>

                <p>Для Обмена надо нажать на карты которые вы хотите поменять и нажать на окно ОБМЕНЯТЬ</p>
                <div className="rule-component">
                    <Exchange />
                </div>

                <p>Если у вас комбинация Стрит и выше, то появится окно «Страховка»</p>
                <div className="rule-component">
                    <Insurance />
                </div>

                <p>При нажатии появится окно «Страховка», так же вам будем предлгаться застроховать комбинацию на 25%, 50%, 75% и 100%.</p>
                <div className="rule-component">
                    <Picture src="/rules/insurance.png" alt="Insurance" />
                </div>


                <p>Вы должны решить, что нажать: «BET» для продолжения раунда игры или «ПАС» для завершения раунда игры с потерей размещенных став</p>
                <div className="rule-component">
                    <Bet />
                    <Fold />
                </div>

                <p>При нажатии на кнопку «BET» вы продолжаете играть и делаете ставку «BET», Ставка «BET» будет автоматически размещена в месте для ставки «BET».</p>

                <p>После щелчка/нажатия кнопки «ПАС» вы потеряете свою ставку АНТЕ.</p>
                <p>Вы сможете наблюдать за ходом игры, но не сможете в ней участвовать. Чтобы сделать новые ставки, вам придется дождаться следующего раунда.</p>
                <p>Если игрок имеет комбинацию Стрит и выше, а у дилера нет игры, игрок может за сумму, равную ставке Анте, попытаться купить игру дилеру.</p>

                <div className="rule-component">
                    <Picture src="/rules/purchase.png" alt="Purchase" />
                </div>

            </div>
    },
    {
        id: 7,
        title: 'Номер игры',
        content:
            <div className="rule-content">
                <p>Каждый раунд игры идентифицируется уникальным НОМЕРОМ ИГРЫ.</p>
                <div className="rule-component">
                    <p><b>#47489</b></p>
                </div>
                <p>Пожалуйста, используйте этот номер (или сделайте снимок экрана с номером игры), если вы хотите обратиться в Службу поддержки с вопросами относительно определенного раунда.</p>
            </div>
    },
    {
        id: 8,
        title: 'Звук',
        content:
            <div className="rule-content">
                <p>Кнопка «ЗВУК» включает/выключает все звуки игры и голоса. Примите к сведению, что в случае смены стола все звуки включатся автоматически.</p>

                <div className="rule-component">
                    <div className="game-control">
                        <Picture src={`${env.mediapoint}/images/music-on.png`} alt="Volume" />
                    </div>
                    <div className="game-control">
                        <Picture src={`${env.mediapoint}/images/volume.png`} alt="Volume" />
                    </div>
                </div>

                <p>Вы можете изменить настройки звука, нажав кнопку «НАСТРОЙКИ» и выбрав вкладку ЗВУК.</p>
            </div>
    },
    {
        id: 9,
        title: 'История игр',
        content:
            <div className="rule-content">
                <p>С помощью кнопки «ИСТОРИЯ» можно открыть окно, в котором будут отображены все сыгранные вами раунды игры и их результаты.</p>

                <div className="rule-component">
                    <div className="game-control">
                        <Picture src={`${env.mediapoint}/images/history.png`} alt="History" />
                    </div>
                </div>

                <p>Вы можете просматривать свою игровую активность с помощью следующего:</p>
                <p>• ИСТОРИЯ УЧЕТНОЙ ЗАПИСИ - отображает всю историю вашей учетной записи в виде списка дат, игр, сумм ставок и выплат.</p>
                <p>Последний завершенный раунд отображается в самом верху списка.</p>
                <p>• ИСТОРИЯ ИГР -- после щелчка/нажатия на определенную игру в столбце ИГРА отображает вашу историю, связанную с определенной игрой.</p>
            </div>
    },
    {
        id: 10,
        title: 'Действия в случае сбоя',
        content:
            <div className="rule-content">
                <p>
                    При сбоях в игре, игровой системе или процедуре игры раунд временно
                    приостанавливается, а ведущий игры уведомляет об этом сервис-менеджера. Вы и
                    другие игроки увидят сообщение в чате или всплывающее сообщение на экране о том,
                    что данный вопрос рассматривается. Если менеджер сможет незамедлительно 2
                    устранить ошибку, раунд игры продолжится, как обычно. В случае отсутствия
                    возможности незамедлительного устранения раунд игры аннулируется, а изначальные
                    ставки подлежат возмещению всем игрокам, принявшим в нем участие.
                </p>
            </div>
    },
    {
        id: 11,
        title: 'Политика отключения',
        content:
            <div className="rule-content">
                <p>
                    В случае отключения от игры до истечения времени для размещения ставок любые
                    сделанные ставки признаются недействительными и возвращаются вам. В случае
                    отключения после истечения времени для размещения ставок, сделанные ставки
                    признаются действительными и разыгрываются без вашего участия. Результаты
                    розыгрыша ставки можно посмотреть в окне «История» после повторного
                    подключения.
                </p>
            </div>
    }
]

const en = [
    {
        id: 1,
        title: 'Game Rules',
        content: (
            <div className="rule-content">
                <h3>EUROPEAN POKER</h3>

                <p>The goal of the game is to have a stronger poker hand than the dealer.</p>
                <p>One standard deck of 52 cards is used for the game.</p>
                <p>The player makes an Ante bet within the minimum and maximum limits of the table and receives only 4 cards. The dealer has 5 cards, one of which is face up.</p>
                <p>If the player looks at their 4 cards and decides to fold, they lose only half of the Ante bet. Alternatively, the player can continue with their 4 cards. If the player decides to play, they must make a Bet equal to two Antes.</p>
                <p>For an amount equal to the Ante bet, the player can purchase a fifth card or exchange one to four cards, with the player receiving one additional card in the exchange. Thus, after the exchange, the player will have 5 cards. After this, the player must decide whether to continue playing or not.</p>
                <p>If the player decides to play, they must make a Bet equal to two Antes. If the player decides not to play (Fold), they lose the Ante bet. After all players have made their decisions, the dealer reveals their cards.</p>
                <p>If the dealer has a qualifying hand, they compare it to the players' poker hands. The dealer has a qualifying hand if they have a pair of Ace-King or higher. If the dealer does not qualify, only the Ante bet is paid out at 1:1. If the player's hand is stronger than the dealer's, the Ante bet is paid out at 1:1 and the Bet is paid according to the payout table.</p>
                <p>If the player's hand is weaker than the dealer's, their Ante and Bet bets lose.</p>
                <p>If the player has a Straight or better, they can insure their hand against the dealer having no qualifying hand. To do this, they must place a bet of 25, 50, 75, or 100 percent of the expected payout on the Bet. If the payout on the Bet exceeds the maximum payout at the table, the player bets 25, 50, 75, or 100 percent of the maximum payout. The Insurance bet wins if the dealer has no qualifying hand; otherwise, the Insurance bet loses. The Insurance bet is paid at 1:1.</p>
                <p>If the player has a combination of Straight or better, and the dealer does not have a game, the player can try to buy the dealer's game for an amount equal to the Ante bet. In this case, the dealer changes the highest card. If after the card exchange the dealer does not have a game combination, the Ante bet is not paid. If the dealer has bought the game, then if the player wins, only the Bet bet is paid in accordance with the payout table.</p>
                <p>The maximum payout is the amount that can be paid out for a hand, including insurance. The maximum payout does not include the Ante, Bet, and Insurance bets themselves.</p>

                <p>Payout Table</p>
                <table className="rules-table">
                    <tbody>
                        <tr>
                            <td>Ace-King with 5 cards</td>
                            <td>1:1</td>
                        </tr>
                        <tr>
                            <td>Ace-King with 4 cards</td>
                            <td>3:1</td>
                        </tr>
                        <tr>
                            <td>One Pair</td>
                            <td>1:1</td>
                        </tr>
                        <tr>
                            <td>Two Pairs</td>
                            <td>3:1</td>
                        </tr>
                        <tr>
                            <td>Three of a Kind</td>
                            <td>4:1</td>
                        </tr>
                        <tr>
                            <td>Straight</td>
                            <td>5:1</td>
                        </tr>
                        <tr>
                            <td>Flush</td>
                            <td>6:1</td>
                        </tr>
                        <tr>
                            <td>Full House</td>
                            <td>7:1</td>
                        </tr>
                        <tr>
                            <td>Four of a Kind with 5 cards</td>
                            <td>20:1</td>
                        </tr>
                        <tr>
                            <td>Four of a Kind with 4 cards</td>
                            <td>40:1</td>
                        </tr>
                        <tr>
                            <td>Straight Flush</td>
                            <td>50:1</td>
                        </tr>
                        <tr>
                            <td>Royal Flush</td>
                            <td>100:1</td>
                        </tr>
                    </tbody>
                </table>

                <p>In case of a tie in hands, the winner is determined by the highest card in the poker hand, followed by the next highest cards. In a Straight combination, 5-4-3-2-A, the Ace is the lowest card.</p>

                <p>In the case of a complete tie with all cards, it results in a "Stand off" (draw).</p>
            </div>
        ),
    },
    {
        id: 2,
        title: 'Maximum Payout',
        content:
            <div className="rule-content">
                <p>Maximum Payout – the amount that can be paid out on a box, including Insurance. The Maximum Payout does not include the bets on Ante, Bet and Insurance.</p>
            </div>
    },
    {
        id: 3,
        title: 'Poker Hands',
        content:
            <div className="rule-content">

                <p>- Individual cards are listed in descending order: Ace (high or low), King, Queen, Jack, 10, 9, 8, 7, 6, 5, 4, 3, and 2.
                    The Ace can be the highest card in a straight of Ace, King, Queen, Jack, and 10 or the lowest in a straight of 5, 4, 3, 2, and Ace.
                    Possible hands in descending order:</p>

                <p>Royal Flush - a straight flush consisting of Ace, King, Queen, Jack, and 10 of the same suit.</p>
                <div className="rules-combinations">
                    <div className="rules-combination">
                        <div className="rules-combination-text">10</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">J</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">Q</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">K</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">A</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                </div>

                <p>Straight Flush - a hand consisting of five consecutive cards of the same suit, for example: 10, 9, 8, 7, and 6, all hearts. Two straight flushes are compared by their highest card.</p>
                <div className="rules-combinations">
                    <div className="rules-combination">
                        <div className="rules-combination-text">6</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">7</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">8</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">9</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">10</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                </div>

                <p>Four of a Kind - a hand consisting of four cards of the same rank and any other card. For example, four fours. A four of a kind with higher-ranked cards beats one with lower-ranked cards.</p>
                <div className="rules-combinations">
                    <div className="rules-combination">
                        <div className="rules-combination-text">4</div>
                        <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Spade" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">4</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">4</div>
                        <Picture src={`${env.mediapoint}/images/suits/diamond.png`} alt="Diamond" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">4</div>
                        <Picture src={`${env.mediapoint}/images/suits/clubs.png`} alt="Club" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">A</div>
                        <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Spade" />
                    </div>
                </div>

                <p>Full House - a hand consisting of three cards of one rank and two cards of another rank, for example: three kings and two twos. The hand with the higher three of a kind is the winning full house.</p>
                <div className="rules-combinations">
                    <div className="rules-combination">
                        <div className="rules-combination-text">K</div>
                        <Picture src={`${env.mediapoint}/images/suits/diamond.png`} alt="Diamond" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">K</div>
                        <Picture src={`${env.mediapoint}/images/suits/clubs.png`} alt="Club" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">K</div>
                        <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Spade" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">2</div>
                        <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Spade" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">2</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                </div>

                <p>Flush - a hand consisting of any five cards of the same suit in any sequence, for example: five hearts. Two flushes are compared based on the highest card in each hand. If both hands have the same high card, the next highest card is compared, and so on, until a winner is determined. If all cards are the same, it's a tie.</p>
                <div className="rules-combinations">
                    <div className="rules-combination">
                        <div className="rules-combination-text">3</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">6</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">9</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">10</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">Q</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                </div>

                <p>Straight - a hand consisting of five consecutive cards of different suits, for example: Ace, 2, 3, 4, and 5 of mixed suits. Two straights are compared by their highest card. Straights with the same high card are of equal value, as suits are not considered in the comparison.</p>
                <div className="rules-combinations">
                    <div className="rules-combination">
                        <div className="rules-combination-text">A</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">2</div>
                        <Picture src={`${env.mediapoint}/images/suits/diamond.png`} alt="Diamond" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">3</div>
                        <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Spade" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">4</div>
                        <Picture src={`${env.mediapoint}/images/suits/clubs.png`} alt="Club" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">5</div>
                        <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Spade" />
                    </div>
                </div>

                <p>Three of a Kind - a hand consisting of three cards of the same rank and two other cards of different ranks, for example: three tens. The hand with higher-ranked cards wins.</p>
                <div className="rules-combinations">
                    <div className="rules-combination">
                        <div className="rules-combination-text">10</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">10</div>
                        <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Spade" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">10</div>
                        <Picture src={`${env.mediapoint}/images/suits/club.png`} alt="Club" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">K</div>
                        <Picture src={`${env.mediapoint}/images/suits/diamond.png`} alt="Diamond" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">3</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                </div>

                <p>Two Pairs - a hand consisting of two cards of one rank, two cards of another rank, and one other card of a different rank. The hand with the higher pair wins. If the high pairs are equal, the lower pairs are compared. If the lower pairs are also equal, the remaining card is compared to determine the winner.</p>
                <div className="rules-combinations">
                    <div className="rules-combination">
                        <div className="rules-combination-text">Q</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">Q</div>
                        <Picture src={`${env.mediapoint}/images/suits/diamond.png`} alt="Diamond" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">5</div>
                        <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Spade" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">5</div>
                        <Picture src={`${env.mediapoint}/images/suits/club.png`} alt="Club" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">K</div>
                        <Picture src={`${env.mediapoint}/images/suits/diamond.png`} alt="Diamond" />
                    </div>
                </div>

                <p>One Pair - a hand consisting of two cards of one rank and three other cards of different ranks. For example: two nines and any other three cards. The hand with the higher pair wins. If the pairs are equal, the highest of the other cards wins, and so on.</p>
                <div className="rules-combinations">
                    <div className="rules-combination">
                        <div className="rules-combination-text">9</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">9</div>
                        <Picture src={`${env.mediapoint}/images/suits/diamond.png`} alt="Diamond" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">A</div>
                        <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Spade" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">5</div>
                        <Picture src={`${env.mediapoint}/images/suits/clubs.png`} alt="Club" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">3</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                </div>

                <p>High Card - a hand that does not qualify under any of the above categories. The hand with the highest card wins. If the highest cards are the same, the second-highest card is compared, and so on.</p>
                <div className="rules-combinations">
                    <div className="rules-combination">
                        <div className="rules-combination-text">A</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">5</div>
                        <Picture src={`${env.mediapoint}/images/suits/diamond.png`} alt="Diamond" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">6</div>
                        <Picture src={`${env.mediapoint}/images/suits/spades.png`} alt="Spade" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">J</div>
                        <Picture src={`${env.mediapoint}/images/suits/club.png`} alt="Club" />
                    </div>
                    <div className="rules-combination">
                        <div className="rules-combination-text">K</div>
                        <Picture src={`${env.mediapoint}/images/suits/heart.png`} alt="Heart" />
                    </div>
                </div>

            </div>
    },
    /*{
        id: 5,
        title: 'Dealer Bonus',
        content: (
            <div className="rule-content">
                <p>The Bonus is an additional bet that can be placed on the dealer.</p>
                <p>The Bonus bet wins if there is a combination of three of a kind ("Three of a Kind") or higher from the five cards, and it is paid according to the payout table based on the dealer's revealed cards. In all other cases, the Bonus bet loses.</p>
                <p>Payout Table</p>
                <table className="rules-table">
                    <tbody>
                        <tr>
                            <td>Three of a Kind</td>
                            <td>10:1</td>
                        </tr>
                        <tr>
                            <td>Straight</td>
                            <td>40:1</td>
                        </tr>
                        <tr>
                            <td>Flush</td>
                            <td>50:1</td>
                        </tr>
                        <tr>
                            <td>Full House</td>
                            <td>70:1</td>
                        </tr>
                        <tr>
                            <td>Four of a Kind</td>
                            <td>200:1</td>
                        </tr>
                        <tr>
                            <td>Straight Flush</td>
                            <td>1500:1</td>
                        </tr>
                        <tr>
                            <td>Royal Flush</td>
                            <td>5000:1</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        ),
    },*/
    {
        id: 6,
        title: 'Place Bets',
        content:
            <div className="rule-content">

                <p>The "BETTING LIMITS" panel shows the minimum and maximum allowed betting limits at the table, which may change from time to time. Open "Betting Limits" to check your current limits.</p>

                <div className="rule-component">
                    <div className="game-info-inner">
                        <div className="game-info-text">EUROPEAN SPADES</div>
                        <div className="game-info-rate">$ 1 - $ 1000</div>
                    </div>
                </div>

                <p>To participate in the game, you will need sufficient funds to cover the bets. Your current BALANCE is displayed on the screen.</p>
                <div className="rule-component">
                    <div className="balance-box">
                        <div className="balance-text">BALANCE:</div>
                        <div className="balance-value">$ 1,270</div>
                    </div>
                </div>

                <p>The "CHIP DISPLAY" indicator allows you to choose the value of each chip you wish to place as a bet. Only chips whose value can be covered by your current balance will be available for selection.</p>
                <div className="rule-component">
                    <div className="chips-container" style={{ transform: 'scale(0.4)' }}>

                        <div className="chips-button">
                            <Picture src={`${env.mediapoint}/images/delete.png`} alt="Delete" />
                            Clear
                        </div>

                        <div className="chips-button">
                            <Picture src={`${env.mediapoint}/images/cancel.png`} alt="Cancel" />
                            Undo
                        </div>

                        <div className="chips-box">
                            {chips.map((chip, index) =>
                                <div key={`${index}`} className="chip">
                                    <Chip color={chip.color} value={chip.value} />
                                </div>
                            )}
                        </div>

                        <div className="chips-button">
                            <span>2x</span>
                            Double
                        </div>

                        <div className="chips-button">
                            <Picture src={`${env.mediapoint}/images/refresh.png`} alt="Refresh" />
                            Repeat
                        </div>

                    </div>
                </div>

                <p>After selecting a chip, place a bet by clicking on the appropriate betting spot on the game table. Each click increases your bet by the value of the selected chip or up to the maximum allowed limit for the selected bet type. Once the maximum bet limit is reached, no additional funds will be accepted, and a message will appear notifying you of the maximum bet.</p>
                <div className="rule-component">
                    <div className="rates" style={{ position: "relative", top: 0, bottom: 0 }}>
                        <div className="bonus">
                            <div style={{ zIndex: 1 }} className="bonus-text">BONUS</div>
                        </div>
                        <div className="ante">
                            <div className="ante-text" style={{ zIndex: 1 }}>ANTE</div>
                        </div>
                        <div className="bet" style={{ opacity: 1 }}>
                            <div className="ante-text" style={{ zIndex: 1 }}>BET</div>
                        </div>
                    </div>
                </div>

                <p>NOTE: Please do not minimize your browser window or switch to other browser tabs while the betting time is active, and bets are being placed. Doing so may be considered leaving the game, and your bets may be rejected for that round.</p>

                <p>The "REPEAT" button allows you to repeat all bets from the previous game round. This button remains active until the first chip is placed.</p>

                <div className="rule-component">
                    <div className="chips-button">
                        <Picture src={`${env.mediapoint}/images/refresh.png`} alt="Refresh" />
                        Repeat
                    </div>
                </div>

                <p>The "DOUBLE" (2x) button becomes available after a bet is placed. With each click, all your bets are doubled up to the maximum limit. Please note, you must have sufficient funds in your account to double ALL of your placed bets.</p>

                <div className="rule-component">
                    <div className="chips-button">
                        <span>2x</span>
                        Double
                    </div>
                </div>

                <p>The "UNDO" button allows you to remove the last placed bet.</p>
                <p>Clicking the "UNDO" button again will cancel the bets one by one in the reverse order they were placed. To remove all your bets, hold down the "UNDO" button.</p>
                <div className="rule-component">
                    <div className="chips-button">
                        <Picture src={`${env.mediapoint}/images/cancel.png`} alt="Cancel" />
                        Undo
                    </div>
                </div>

                <p>The "TOTAL BET" indicator shows the total amount of bets placed in the current round.</p>
                <div className="rule-component">
                    <div className="balance-box">
                        <div className="balance-text blue">TOTAL BET:</div>
                        <div className="balance-value">$ 270</div>
                    </div>
                </div>

                <p>Make a decision</p>
                <p>After your cards are dealt, your card combination will appear along with a decision window.</p>
                <div className="rule-component">
                    <Picture src="/rules/game.png" alt="Game" />
                </div>

                <p>To purchase a 6th card, click the window.</p>
                <div className="rule-component">
                    <Fifth />
                </div>

                <p>To exchange cards, click on the cards you wish to exchange and then click the EXCHANGE window.</p>
                <div className="rule-component">
                    <Exchange />
                </div>

                <p>If you have a Straight or higher, the "Insurance" window will appear.</p>
                <div className="rule-component">
                    <Insurance />
                </div>

                <p>When clicked, the "Insurance" window will appear, offering you the option to insure your hand for 25%, 50%, 75%, or 100%.</p>
                <div className="rule-component">
                    <Picture src="/rules/insurance.png" alt="Insurance" />
                </div>

                <p>You must decide whether to click "BET" to continue the game round or "FOLD" to end the round and forfeit the placed bets.</p>
                <div className="rule-component">
                    <Bet />
                    <Fold />
                </div>

                <p>By clicking the "BET" button, you continue to play, and the "BET" will automatically be placed in the "BET" spot.</p>

                <p>After clicking the "FOLD" button, you will lose your ANTE bet.</p>
                <p>You will be able to observe the game's progress but will not be able to participate. To place new bets, you will need to wait for the next round.</p>
                <p>If a player has a Straight or higher combination, and the dealer does not qualify, the player may attempt to buy a game for the dealer for an amount equal to the Ante bet.</p>

                <div className="rule-component">
                    <Picture src="/rules/purchase.png" alt="Purchase" />
                </div>

            </div>
    },
    {
        id: 7,
        title: 'Game Number',
        content:
            <div className="rule-content">
                <p>Each game round is identified by a unique GAME NUMBER.</p>
                <div className="rule-component">
                    <p><b>#47489</b></p>
                </div>
                <p>Please use this number (or take a screenshot of the game number) if you wish to contact Support with questions regarding a specific round.</p>
            </div>
    },
    {
        id: 8,
        title: 'Sound',
        content:
            <div className="rule-content">
                <p>The "SOUND" button toggles all game sounds and voices on or off. Please note that changing tables will automatically turn sounds back on.</p>

                <div className="rule-component">
                    <div className="game-control">
                        <Picture src={`${env.mediapoint}/images/music-on.png`} alt="Volume" />
                    </div>
                    <div className="game-control">
                        <Picture src={`${env.mediapoint}/images/volume.png`} alt="Volume" />
                    </div>
                </div>

                <p>You can adjust sound settings by pressing the "SETTINGS" button and selecting the SOUND tab.</p>
            </div>
    },
    {
        id: 9,
        title: 'Game History',
        content:
            <div className="rule-content">
                <p>The "HISTORY" button opens a window displaying all the game rounds you have played and their results.</p>

                <div className="rule-component">
                    <div className="game-control">
                        <Picture src={`${env.mediapoint}/images/history.png`} alt="History" />
                    </div>
                </div>

                <p>The information in the History window is for reference purposes only and may not reflect the final balance due to technical issues.</p>
            </div>
    },
    {
        id: 10,
        title: 'Actions in Case of Malfunction',
        content:
            <div className="rule-content">
                <p>
                    In the event of a game, system, or procedural malfunction, the round will be temporarily
                    suspended, and the game host will notify the service manager. You and other players
                    will see a message in the chat or a pop-up message on the screen informing you that the
                    issue is being addressed. If the manager is able to fix the issue immediately, the game round
                    will resume as normal. If immediate resolution is not possible, the game round will be
                    canceled, and the initial bets will be refunded to all participating players.
                </p>
            </div>
    },
    {
        id: 11,
        title: 'Disconnection Policy',
        content:
            <div className="rule-content">
                <p>
                    If you are disconnected from the game before the betting time expires, any placed bets
                    will be considered invalid and refunded to you. If disconnection occurs after the betting time
                    has expired, placed bets will remain valid and the game will proceed without your participation.
                    You can view the results of the bet in the "History" window after reconnecting.
                </p>
            </div>
    }
]

export const rules = { ru, en }