import React, { PureComponent } from "react"

/* Constants */
import { utils } from "../constants"

/* Component Fifth */
class Fifth extends PureComponent {

    render = () => {
        const { onClick, isInsurance } = this.props

        if (isInsurance) {
            return (
                <div onClick={() => onClick()} className="game-button fifth-button mini-button">
                    +1 <span>{utils.translate("CARD")}</span>
                </div>
            )
        }

        return <div onClick={() => onClick()} className="game-button fifth-button">{utils.translate("+1 CARD")}</div>
    }

}

export default Fifth