import React, { PureComponent } from "react"

/* PureComponents */
import { Chip, Picture } from '../components'

/* Constants */
import { utils, sound, env } from '../constants'
import { CHOICE } from "../constants/status"


/* Widget Rates */
class Rates extends PureComponent {

    /* Get Color */
    getColor = value => {

        const { chips } = this.props

        let color = chips[0].color

        chips.forEach(item => {
            if (item.value <= value) {
                color = item.color
            }
        })

        return color
    }

    /* DRAW ANIMATION */
    _animation = (target) => {

        const { list, box } = this.props
        const final = list.filter(e => parseInt(e.box) === parseInt(box))

        return (
            <div className="animated-chips">
                {final.map((chip, index) => {
                    const color = this.getColor(chip.value)

                    if (chip.target === target) {
                        return (
                            <div className={`animated-chip ${chip.type === "add" ? "visible" : "hidden"}`} key={`${index}`}>
                                <Chip color={color} value={chip.value} />
                            </div>
                        )
                    }

                    return null
                })}
            </div>
        )
    }

    add = (target) => {

        const { status, selected, ante, bonus, setStake, list, setList, balance, game, chips, currency, volume, box, all } = this.props

        let stake = { ante: ante, bonus: bonus }

        let totalAnte = 0
        if (all.ante) {
            all.ante.forEach(item => {
                totalAnte = totalAnte + item
            })
        }

        let totalBonus = 0
        if (all.bonus) {
            all.bonus.forEach(item => {
                totalBonus = totalBonus + item
            })
        }

        if (status === "CHOICE" && chips[selected]) {
            const value = chips[selected].value

            if (target === 'ante') {
                stake.ante = ante + value
                totalAnte = totalAnte + value
            }
            else {
                if (!ante) {
                    return
                }
                stake.bonus = bonus + value
                totalBonus = totalBonus + value
            }

            if ((totalAnte * 4 + totalBonus) > balance) {
                utils.toggleError(utils.translate("You don't have enough funds"), "error", "nomoney")
                return
            }

            if ((ante + value > parseInt(game.max) && target === 'ante') || (bonus + value > parseInt(game.maxBonus) && target === 'bonus')) {
                if (target === 'ante') {
                    utils.toggleError(`${utils.translate("Maximum bid")} ${utils.getWithCurrency(game.max, currency)}`, "error", "maxbidante")
                }
                else {
                    utils.toggleError(`${utils.translate("Maximum bid for bonus")} ${utils.getWithCurrency(game.maxBonus, currency)}`, "error", "maxbidbonus")
                }
                return
            }

            setStake(stake, box)

            list.push({ value, type: "add", target, box })
            setList(list)

            if (volume) {
                sound.play('sound', 0.2)
            }
        }
    }

    /* DRAW ANTE */
    _ante = () => {

        const { ante, solution, result, used } = this.props

        const final = (solution === "FOLD" && !used) ? ante / 2 : ante
        const color = this.getColor(final)

        const isHidden = (solution === "FOLD" && used) || (result && result.result === "lose" && solution !== "FOLD")

        return (
            <div onClick={() => this.add('ante')} className="ante">
                <div className="ante-text">ANTE</div>
                <div className={`ante-chip-box ${(isHidden) ? "fold" : "hidden"}`}>{(final && final > 0) ? <Chip color={color} value={final} /> : null}</div>
                {this._animation('ante')}
                {this._foldAnte()}
                {this._winRate("ante")}
            </div>
        )
    }

    /* HALF ANTE ON FOLD */
    _foldAnte = () => {

        const { ante, used, solution } = this.props
        const final = ante / 2
        const color = this.getColor(final)

        if (solution === "FOLD" && !used) {
            return (
                <div className="animated-fold-ante">
                    <Chip color={color} value={final} />
                </div>
            )
        }
    }

    /* ANTE & BONUS MULTIPLIER */
    _winRate = (type) => {

        const { result, bonusResult, ante, bonus } = this.props

        let isWinRate = type === "ante" ? (ante && result && result.anteMultiplier && parseInt(result.anteMultiplier) > 0) : (bonus && bonusResult && bonusResult.bonusMultiplier && parseInt(bonusResult.bonusMultiplier))

        if (isWinRate) {

            const value = type === "ante" ? ante * result.anteMultiplier : bonus * bonusResult.bonusMultiplier
            const multiplier = type === "ante" ? result.anteMultiplier : bonusResult.bonusMultiplier
            const color = this.getColor(value)

            return (
                <div className="rate-win-ab">
                    <div className="rate-win-ab-text">{multiplier}x</div>
                    <div className="rate-win-ab-chip">
                        <Chip color={color} value={value} />
                    </div>
                </div>
            )
        }
    }

    /* DRAW BONUS */
    _bonus = () => {

        const { ante, bonus, bonusResult, status } = this.props
        const color = this.getColor(bonus)

        const choice = status === CHOICE
        const isHidden = bonusResult && bonusResult.result === "lose"
        const active = ((choice && ante) || bonus) && !isHidden

        return (
            <div className="bonus" style={{ opacity: 0.5, cursor: 'default' }} /*onClick={() => this.add('bonus')} style={{ opacity: active ? 1 : 0.5 }}*/>
                <div className="bonus-text">DEALER BONUS</div>
                <div className={`bonus-chip-box ${(isHidden) ? "fold" : "hidden"}`}>{(ante && ante > 0) ? <Chip color={color} value={bonus} /> : null}</div>
                {this._animation('bonus')}
                {this._winRate("bonus")}
            </div>
        )
    }

    /* BET MULTIPLIER */
    _winBet = () => {

        const { bet, result, game } = this.props

        if (bet && result && result.betMultiplier && parseInt(result.betMultiplier) > 0) {

            const maxPay = game && game.maxPay && parseFloat(game.maxPay) > 0 ? parseFloat(game.maxPay) : 0
            const value = maxPay < parseFloat(bet * result.betMultiplier) ? maxPay : bet * result.betMultiplier

            const color = this.getColor(value)

            return (
                <div className="rate-win-bet">
                    <div className="rate-win-bet-text">{result.betMultiplier}x</div>
                    <div className="rate-win-bet-chip">
                        <Chip color={color} value={value} />
                    </div>
                </div>
            )
        }
    }


    /* Draw BET */
    _bet = () => {

        const { status, bet, solution, result, bottomBet } = this.props
        const color = this.getColor(bet)

        return (
            <div onClick={() => bottomBet()} className="bet" style={{ opacity: status === "CHOICE" ? 0.6 : 1 }}>
                <div className="bet-text">BET</div>
                {(bet && bet > 0) ? <div className={`bet-chip-box ${(solution === "FOLD" || (result && result.result === "lose")) ? "fold" : "hidden"}`}> <Chip color={color} value={bet} /></div> : null}
                {this._winBet()}
            </div>
        )
    }


    /* Draw Buy */
    _buy = () => {
        const { ante, chip } = this.props
        const value = ante
        const color = this.getColor(value)

        if (chip) {
            return (
                <div className="rate-buy">
                    <Chip value={value} color={color} />
                </div>
            )
        }
    }


    /* Draw Buy */
    _insurance = () => {

        const { insurance, insuranceStatus } = this.props
        const color = this.getColor(insurance)

        let insuranceValue = insurance
        let image = `${env.mediapoint}/images/rates/shield.png`

        let text = ""

        if (insuranceStatus === "win") {
            text = utils.translate("Insurance won")
            insuranceValue = insurance * 2
            image = `${env.mediapoint}/images/rates/shield-green.png`
        }

        if (insuranceStatus === "lose") {
            text = utils.translate("Insurance lost")
            insuranceValue = 0
            image = `${env.mediapoint}/images/rates/shield-red.png`
        }

        if (insurance > 0) {
            return (
                <div className="rate-insurance">

                    <div className="rate-insurance-image">
                        <Picture src={image} alt="Shield" />
                    </div>

                    <div className="rate-insurance-chip">
                        <Chip size={60} value={insuranceValue} color={color} />
                    </div>

                    {text.length > 0 && <p className="rate-insurance-text">{text}</p>}

                </div>
            )
        }
    }


    render = () => {

        const { status, ante } = this.props

        return (
            <div className="rates" style={{ opacity: status !== "CHOICE" && ante === 0 ? 0.3 : 1 }}>
                {/* {this._bonus()} */}
                {this._ante()}
                {this._bet()}
                {this._buy()}
                {this._insurance()}
            </div>
        )
    }

}

export default Rates